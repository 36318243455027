
.tooltiptrigger:hover .tooltiptext{
  opacity: 1;
}
/*
.tooltiptrigger {
position: relative;
display: inline;
} */

.tooltiptrigger .tooltiptext{
width: 50%;
background-color: black;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px;
opacity: 0;
transition: opacity 1s;
position: absolute;
z-index: 1;
display: inline-block;
cursor: pointer;
}

.tooltiptrigger .tooltiptext-right::after{
content: " ";
position: absolute;
top: 50%;
right: 100%;
margin-top: -5px;
border-width: 5px;
border-style: solid;
border-color: transparent black transparent transparent;
}
