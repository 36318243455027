@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 4px 0 2px 0;


  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }
}
.blockBookItem {
  margin: 15px 0;
  padding: 10px 0;
  display: flex;
  border: 1px solid #d8dce6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;

    /* Fix broken multi-column layout in Chrome */
    page-break-inside: avoid;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }
}
.quantityField {
  width: 30%;


}
